import { fetchInvoicesOptions } from '@/models/payment';
import { fetchProfileOptions } from '@/models/profile';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_layout/billing')({
  loader: async ({ context }) => {
    const profile =
      await context.queryClient.ensureQueryData(fetchProfileOptions);
    const invoices =
      await context.queryClient.ensureQueryData(fetchInvoicesOptions);

    return { profile, invoices };
  },
});

import {
  CircleUserIcon,
  CoinsIcon,
  LayoutTemplateIcon,
  TerminalSquareIcon,
} from 'lucide-react';

export const ACCESS_COOKIE = 'jwt-access-token';
export const REFRESH_COOKIE = 'jwt-refresh-token';
export const ACCESS_TOKEN = 'access-token';
export const REFRESH_TOKEN = 'refresh-token';

export const LLM_MODEL = 'llm:selected-model';
export const LLM_KEY = 'llm:selected-key';
export const EXPERIMENTS = 'llm:experiments';

export const LINKS = [
  // { text: 'Dashboard', href: '/dashboard' },
  { text: 'Chat', href: '/chat' },
] as const;

export const navigationLinks = [
  { name: 'Playground', icon: TerminalSquareIcon, href: '/chat' },
  { name: 'Billing', icon: CoinsIcon, href: '/billing' },
  {
    name: 'Templates',
    icon: LayoutTemplateIcon,
    children: [
      { name: 'New Template', href: '/templates/new' },
      { name: 'Public', href: '/templates/public' },
      { name: 'Favorites', href: '/templates/favorites' },
    ],
  },
  { name: 'Profile', icon: CircleUserIcon, href: '/profile' },
] as const;

// USD formatter
export const formatUSD = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
});

export const publicTemplates = [
  {
    id: 1,
    title: 'Marketing Content Creation for Product or Service',
    description:
      'An AI-drafted framework to create a compelling marketing text that emphasizes the unique features and benefits of your product or service to your ideal customer persona, persuading them to make a purchase.',
    publisher: 'bytepath',
  },
  {
    id: 2,
    title: 'Craft a Convincing Blog Post to Promote a Product or Service',
    description:
      'A handy AI prompt template designed to generate detailed, persuasive blog post drafts focusing on highlighting the utility and benefits of a certain product or service to a targeted customer persona.',
    publisher: 'bytepath',
  },
  {
    id: 3,
    title: 'Marketing Campaign Outline using Unique Selling Proposition',
    description:
      'This template instructs an AI to generate an effective marketing campaign outline using the Unique Selling Proposition approach. It focuses on the unique selling points of a product or service targeting a specific customer persona.',
    publisher: 'bytepath',
  },
];

import { api } from '@/utils/fetch';
import { queryOptions } from '@tanstack/react-query';

export interface Profile {
  readonly pk: number;
  readonly email: string;
  first_name: string;
  last_name: string;
  readonly subscribed: boolean;
  readonly remaining_queries: number | null;
}

export async function fetchProfile(options?: DefaultFetchOptions) {
  return api<Profile>('/api/v1/auth/me/', { signal: options?.signal });
}

export const fetchProfileOptions = queryOptions({
  queryKey: ['me'],
  queryFn: ({ signal }) => fetchProfile({ signal }),
  staleTime: Number.POSITIVE_INFINITY,
});

export type ProfileInput = Pick<Profile, 'first_name' | 'last_name'>;

export async function updateProfile(
  body: ProfileInput,
  options?: DefaultFetchOptions,
) {
  return api<Profile>('/api/v1/auth/me/', {
    method: 'PATCH',
    body,
    signal: options?.signal,
  });
}

export type ResetPasswordInput = {
  email: string;
};

export async function resetPassword(
  body: ResetPasswordInput,
  options?: DefaultFetchOptions,
) {
  return api('/api/v1/auth/password-reset/', {
    method: 'POST',
    body,
    signal: options?.signal,
  });
}

export type ResetPasswordConfirmInput = {
  new_password1: string;
  new_password2: string;
  uid: string;
  token: string;
};

export async function resetPasswordConfirm(
  body: ResetPasswordConfirmInput,
  options?: DefaultFetchOptions,
) {
  return api(`/api/v1/auth/password-reset-confirm/${body.uid}/${body.token}/`, {
    method: 'POST',
    body,
    signal: options?.signal,
  });
}

import { Prose } from '@/components/prose';
import { buttonVariants } from '@/components/ui/button';
import { Link } from '@tanstack/react-router';
import {
  GitCompareIcon,
  MonitorIcon,
  NetworkIcon,
  SwatchBookIcon,
  TerminalIcon,
} from 'lucide-react';

const features = [
  {
    id: 1,
    name: 'Centralized access to diverse LLMs',
    description:
      'Explore and utilize a wide range of language models, each offering unique capabilities',
    icon: NetworkIcon,
  },
  {
    id: 2,
    name: 'Intuitive interface',
    description:
      'Submit prompts, receive results, and manage your workflow with effortless ease.',
    icon: MonitorIcon,
  },
  {
    id: 3,
    name: 'Advanced prompt crafting',
    description:
      'Leverage our built-in prompt engineering tools to optimize your queries for the best possible LLM outputs.',
    icon: TerminalIcon,
  },
  {
    id: 4,
    name: 'Comparative analysis',
    description:
      'Compare and contrast outputs from different LLMs to gain deeper insights and identify the most suitable solution.',
    icon: GitCompareIcon,
  },
  {
    id: 5,
    name: 'Customization options',
    description: 'Tailor the platform to your specific needs and preferences.',
    icon: SwatchBookIcon,
  },
];

export function HomeFeatures() {
  return (
    <section className="container px-4 py-10 lg:px-8 sm:px-6 lg:py-14">
      <div className="grid items-center gap-6 lg:grid-cols-3 sm:grid-cols-2">
        {features.map((feature) => (
          <article
            className="group flex h-full w-full gap-y-6 rounded-lg p-5 transition-all"
            key={feature.id}
          >
            <feature.icon className="me-6 mt-0.5 h-8 w-8 flex-shrink-0 text-gray-800" />

            <div>
              <div>
                <h3 className="block font-bold text-gray-800">
                  {feature.name}
                </h3>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            </div>
          </article>
        ))}
      </div>

      <div className="flex flex-col items-center justify-center gap-10 py-6">
        <h2 className="text-center font-bold text-5xl">Try it for free</h2>
        <Link className={buttonVariants()} to="/signup">
          Sign Up
        </Link>
      </div>

      <Prose className="container mt-24">
        <h3>Harness the collective intelligence of leading LLMs:</h3>
        <p>
          Our platform connects you to a comprehensive suite of powerful
          language models, each specializing in various tasks.
        </p>
        <h3>Seamless integration, effortless results:</h3>
        <p>
          Forget navigating individual LLM interfaces. Our user-friendly
          platform streamlines your experience, allowing you to submit prompts
          and receive outputs effortlessly.
        </p>
        <h3>Tailored solutions for every need:</h3>
        <p>
          Whether you're a writer, translator, developer, or simply someone
          seeking creative inspiration, our LLM aggregator empowers you to find
          the perfect tool for the job.
        </p>
        <h3>Enhanced efficiency, maximized potential:</h3>
        <p>
          Save time and resources by leveraging the combined expertise of
          multiple LLMs. Get more done, faster, and achieve superior results.
        </p>
      </Prose>
    </section>
  );
}

export function HomeHero() {
  return (
    <section className="relative overflow-hidden">
      <div
        aria-hidden="true"
        className="-top-96 -translate-x-1/2 absolute start-1/2 flex transform"
      >
        <div className="-translate-x-[10rem] h-[44rem] w-[25rem] rotate-[-60deg] transform bg-gradient-to-r from-violet-300/50 to-purple-100 blur-3xl" />
        <div className="-translate-x-[15rem] -rotate-12 h-[50rem] w-[90rem] origin-top-left rounded-fulls bg-gradient-to-tl from-blue-50 via-blue-100 to-blue-50 blur-3xl" />
      </div>

      <div className="relative z-10">
        <div className="mx-auto max-w-[85rem] px-4 py-10 lg:px-8 sm:px-6 lg:py-16">
          <div className="mx-auto max-w-2xl text-center">
            <p className="inline-block bg-gradient-to-l from-blue-600 to-violet-500 bg-clip-text font-medium text-sm text-transparent">
              Access the Full Potential of Large Language Models - All in One
              Place
            </p>

            <div className="mt-5 max-w-2xl">
              <h1 className="block font-semibold text-4xl text-gray-800 lg:text-6xl md:text-5xl">
                Unleash the Power of AI: Introducing BytePath
              </h1>
            </div>

            <div className="mt-5 max-w-3xl">
              <p className="text-gray-600 text-lg">
                Simplify your workflow and unlock transformative capabilities
                with our cutting-edge LLM aggregator platform.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

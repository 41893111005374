import { Button } from '@/components/ui/button';
import {
  DialogClose,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { useDialogs } from '@/dialogs';
import { createSubscription } from '@/models/payment';
import { useState } from 'react';

export function DialogPaywall() {
  const [isDisabled, setIsDisabled] = useState(false);
  const { setOpen } = useDialogs();

  return (
    <>
      <DialogHeader>
        <DialogTitle>Start your subscription today</DialogTitle>
        <DialogDescription>
          This is just the tip of the iceberg.
          <br /> Become a subscriber to gain full access.
        </DialogDescription>
      </DialogHeader>

      <p className="text-muted-foreground">
        <strong className="text-4xl text-primary">$20</strong> billed monthly
      </p>

      <DialogFooter>
        <DialogClose>Close</DialogClose>
        <Button
          type="button"
          disabled={isDisabled}
          onClick={async () => {
            setIsDisabled(true);
            const { url } = await createSubscription();
            setIsDisabled(false);
            setOpen(false);
            window.location.href = url;
          }}
        >
          Subscribe
        </Button>
      </DialogFooter>
    </>
  );
}

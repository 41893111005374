import { AuthProvider, useAuth } from '@/auth';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { RouterProvider, createRouter } from '@tanstack/react-router';
import { renderDOMHead } from '@unhead/dom';
import { StrictMode, useEffect } from 'react';
import { CookiesProvider } from 'react-cookie';
import { createRoot } from 'react-dom/client';
import { createHead } from 'unhead';
import './index.css';
import { DialogsProvider } from '@/dialogs';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { FetchError } from 'ofetch';
import TagManager from 'react-gtm-module';
// Import the generated route tree
import { routeTree } from './routeTree.gen';

if (import.meta.env.PROD) {
  TagManager.initialize({ gtmId: 'GTM-KGF6FJDZ' });

  Sentry.init({
    dsn: 'https://68405edc0aced4158f2360ae1b029aa7@o1400854.ingest.us.sentry.io/4507328570589184',
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    tracePropagationTargets: ['localhost', /^https:\/\/bytepath\.ai\/api/],
    replaysSessionSampleRate: import.meta.env.PROD ? 0.1 : 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const queryClient = new QueryClient();

// Create a new router instance
const router = createRouter({
  routeTree,
  context: {
    queryClient,
    // biome-ignore lint/style/noNonNullAssertion: <explanation>
    auth: undefined!,
  },
  defaultStaleTime: Number.POSITIVE_INFINITY,
  defaultPreload: 'intent',
  defaultPreloadStaleTime: 0,
});

router.subscribe('onLoad', ({ fromLocation, toLocation }) => {
  if (import.meta.env.PROD) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'historyChange',
        info: {
          from: fromLocation.pathname,
          to: toLocation.pathname,
        },
      },
    });
  }
});

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
    defaultError: FetchError;
  }
}

const head = createHead();
renderDOMHead(head);

// Render the app
// biome-ignore lint/style/noNonNullAssertion: <explanation>
const rootElement = document.getElementById('root')!;

if (!rootElement.innerHTML) {
  const root = createRoot(rootElement);
  root.render(
    <StrictMode>
      <AuthProvider>
        <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
          <CookiesProvider
            defaultSetOptions={{
              path: '/',
              secure: import.meta.env.PROD,
              sameSite: 'lax',
            }}
          >
            <DialogsProvider>
              <QueryClientProvider client={queryClient}>
                <InnerApp />
              </QueryClientProvider>
            </DialogsProvider>
          </CookiesProvider>
        </GoogleOAuthProvider>
      </AuthProvider>
    </StrictMode>,
  );
}

function InnerApp() {
  const auth = useAuth();

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        visitorType: auth.isAuthenticated ? 'logged' : 'notLogged',
      },
    });
  }, [auth.isAuthenticated]);

  return <RouterProvider router={router} context={{ auth }} />;
}

import { z } from 'zod';

export const ErrorResponseDetail = z.object({
  data: z.object({ detail: z.string(), code: z.string().optional() }),
});

export const ErrorNotSubscribed = z.object({
  error: z.string(),
  type: z.number(),
});

export function isErrorResponseDetail(
  data: unknown,
): data is z.infer<typeof ErrorResponseDetail> {
  return ErrorResponseDetail.safeParse(data).success;
}

export function isErrorNotSubscribed(
  data: unknown,
): data is z.infer<typeof ErrorNotSubscribed> {
  return ErrorNotSubscribed.safeParse(data).success;
}

// Register form errors guards
export const ErrorRegisterForm = z.object({
  data: z.object({
    first_name: z.array(z.string().optional()),
    last_name: z.array(z.string().optional()),
    email: z.array(z.string().optional()),
    password1: z.array(z.string().optional()),
    password2: z.array(z.string().optional()),
    non_field_errors: z.array(z.string().optional()).optional(),
  }),
});

export function isErrorRegisterForm(
  data: unknown,
): data is z.infer<typeof ErrorRegisterForm> {
  return ErrorRegisterForm.safeParse(data).success;
}

export const ValidationError = z.object({
  type: z.literal('validation_error'),
  errors: z.array(
    z.object({
      code: z.string(),
      detail: z.string(),
      attr: z.string().optional(),
    }),
  ),
});

export function isValidationError(
  data: unknown,
): data is z.infer<typeof ValidationError> {
  return ValidationError.safeParse(data).success;
}

export const ServerError = z.object({
  type: z.literal('server_error'),
  errors: z.array(
    z.object({
      code: z.string(),
      detail: z.string(),
      attr: z.string().optional(),
    }),
  ),
});

export function isServerError(
  data: unknown,
): data is z.infer<typeof ServerError> {
  return ServerError.safeParse(data).success;
}

import type { AuthContext } from '@/auth';
import { Toaster } from '@/components/ui/sonner';
import type { QueryClient } from '@tanstack/react-query';
import {
  Outlet,
  ScrollRestoration,
  createRootRouteWithContext,
} from '@tanstack/react-router';
import { defineWebSite, useSchemaOrg } from '@unhead/schema-org';
import { lazy } from 'react';
import { useHead, useSeoMeta } from 'unhead';

const TanStackRouterDevtools =
  process.env.NODE_ENV === 'production'
    ? () => null // Render nothing in production
    : lazy(() =>
        // Lazy load in development
        import('@tanstack/router-devtools').then((res) => ({
          default: res.TanStackRouterDevtools,
          // For Embedded Mode
          // default: res.TanStackRouterDevtoolsPanel
        })),
      );

export const Route = createRootRouteWithContext<{
  queryClient: QueryClient;
  auth: AuthContext;
}>()({
  component: Root,
});

const canonicalUrl = 'https://bytepath.ai';
const siteName = 'BytePath';
const siteDescription =
  'With BytePath, you no longer need to opt for ChatGPT Plus and can instead use free or pay-per-call APIs.';
const siteImage = 'https://bytepath.ai/logo.png';

function Root() {
  useHead({
    titleTemplate: '%s %separator %siteName',
    templateParams: { separator: '|', siteName },
    meta: [
      {
        name: 'description',
        content: siteDescription,
      },
    ],
    link: [{ rel: 'canonical', href: canonicalUrl }],
  });
  useSeoMeta({
    ogTitle: 'BytePath',
    ogDescription:
      'With BytePath, you no longer need to opt for ChatGPT Plus and can instead use free or pay-per-call APIs.',
    ogImage: siteImage,
    twitterCard: 'summary',
    twitterTitle: siteName,
    twitterDescription: siteDescription,
    twitterImage: siteImage,
  });
  useSchemaOrg([
    defineWebSite({
      url: canonicalUrl,
      name: siteName,
      description: siteDescription,
      inLanguage: 'en-US',
    }),
  ]);

  return (
    <>
      <Outlet />
      <Toaster />
      <ScrollRestoration />
      <TanStackRouterDevtools />

      <script
        id="CookieDeclaration"
        src="https://consent.cookiebot.com/7fa0a9cd-b6cc-49fe-b247-41f7d3fc83f8/cd.js"
        type="text/javascript"
        async
      />
    </>
  );
}

import { Link } from '@tanstack/react-router';

export function Footer() {
  return (
    <footer className="body-font border-t text-gray-600">
      <div className="container flex flex-col items-center py-8 sm:flex-row">
        <Link className="flex items-center gap-x-2" to="/">
          <img
            className="aspect-square w-10 rounded-full"
            src="/header-logo.webp"
            alt="BytePath logo"
          />
          <span className="font-bold text-xl tracking-tight">BytePath</span>
        </Link>
        <p className="mt-4 text-gray-500 text-sm sm:mt-0 sm:ml-4 sm:border-gray-200 sm:border-l-2 sm:py-2 sm:pl-4">
          &copy; {new Date().getFullYear()} BytePath
        </p>
        <Link
          className="mt-4 text-gray-500 sm:mt-0 sm:ml-4 sm:py-2 sm:pl-4"
          to="/privacy-policy"
        >
          Privacy policy
        </Link>
        <span className="mt-4 inline-flex justify-center sm:mt-0 sm:ml-auto sm:justify-start">
          <a className="text-gray-500" href="/">
            {/* biome-ignore lint/a11y/noSvgWithoutTitle: <explanation> */}
            <svg
              fill="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              className="h-5 w-5"
              viewBox="0 0 24 24"
            >
              <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z" />
            </svg>
          </a>
          <a className="ml-3 text-gray-500" href="/">
            {/* biome-ignore lint/a11y/noSvgWithoutTitle: <explanation> */}
            <svg
              fill="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              className="h-5 w-5"
              viewBox="0 0 24 24"
            >
              <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z" />
            </svg>
          </a>
          <a className="ml-3 text-gray-500" href="/">
            {/* biome-ignore lint/a11y/noSvgWithoutTitle: <explanation> */}
            <svg
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              className="h-5 w-5"
              viewBox="0 0 24 24"
            >
              <rect width="20" height="20" x="2" y="2" rx="5" ry="5" />
              <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01" />
            </svg>
          </a>
          <a className="ml-3 text-gray-500" href="/">
            {/* biome-ignore lint/a11y/noSvgWithoutTitle: <explanation> */}
            <svg
              fill="currentColor"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0"
              className="h-5 w-5"
              viewBox="0 0 24 24"
            >
              <path
                stroke="none"
                d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
              />
              <circle cx="4" cy="4" r="2" stroke="none" />
            </svg>
          </a>
        </span>
      </div>
    </footer>
  );
}

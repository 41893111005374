import { DialogPaywall } from '@/components/dialogs/dialog-paywall';
import { DialogPricing } from '@/components/dialogs/dialog-pricing';
import { DialogUnsubscribe } from '@/components/dialogs/dialog-unsubscribe';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import {
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';

export interface DialogsContext {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  showDialog: (name: keyof typeof dialogs) => void;
}

const dialogs: Record<string, ReactNode> = {
  paywall: <DialogPaywall />,
  pricing: <DialogPricing />,
  unsubscribe: <DialogUnsubscribe />,
};

const DialogsContext = createContext<DialogsContext | null>(null);

export function DialogsProvider({ children }: { children: ReactNode }) {
  const [open, setOpen] = useState(false);
  const [dialog, setDialog] = useState('');

  const showDialog = useCallback((name: keyof typeof dialogs) => {
    setOpen(true);
    setDialog(name);
  }, []);

  return (
    <DialogsContext.Provider value={{ open, setOpen, showDialog }}>
      {children}
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent>{dialog ? dialogs[dialog] : null}</DialogContent>
      </Dialog>
    </DialogsContext.Provider>
  );
}

export function useDialogs() {
  const context = useContext(DialogsContext);
  if (!context) {
    throw new Error('useDialogs must be used within an DialogsProvider');
  }
  return context;
}

import { useAuth } from '@/auth';
import { MobileNavigation } from '@/components/mobile-navigation';
import { Button, buttonVariants } from '@/components/ui/button';
import { LINKS } from '@/utils/constants';
import { Link } from '@tanstack/react-router';

export function Header() {
  const { isAuthenticated, isLoading, logout } = useAuth();

  return (
    <header className="z-50 border-gray-200 border-b bg-white">
      <div className="mx-auto flex max-w-[85rem] items-center justify-between px-4 py-3">
        <Link className="flex items-center gap-x-2" to="/">
          <img
            className="aspect-square w-10 rounded-full"
            src="/header-logo.webp"
            alt="BytePath logo"
          />
          <span className="font-bold text-xl tracking-tight">BytePath</span>
        </Link>

        <nav aria-label="Global">
          <div className="overflow-hidden transition-all duration-300">
            {isAuthenticated ? (
              <>
                <div className="hidden md:block">
                  {LINKS.map(({ text, href }) => (
                    <Link
                      className={buttonVariants({ variant: 'ghost' })}
                      to={href}
                      key={href}
                    >
                      {text}
                    </Link>
                  ))}
                  <Button
                    variant="ghost"
                    type="button"
                    disabled={isLoading}
                    onClick={() => {
                      logout();
                    }}
                  >
                    Sign Out
                  </Button>
                </div>
                <MobileNavigation />
              </>
            ) : (
              <>
                <Link
                  className={buttonVariants({ variant: 'ghost' })}
                  to="/signin"
                >
                  Sign In
                </Link>
                <Link
                  className={buttonVariants({ variant: 'ghost' })}
                  to="/signup"
                >
                  Sign Up
                </Link>
              </>
            )}
          </div>
        </nav>
      </div>
    </header>
  );
}

import { ACCESS_COOKIE, REFRESH_COOKIE } from '@/utils/constants';
import { api, clearCredentials, setCredentials } from '@/utils/fetch';
import {
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useCookies } from 'react-cookie';

export interface AuthContext {
  isAuthenticated: boolean;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  login: (body: LoginInput) => Promise<void>;
  logout: () => Promise<void>;
}

export interface Credentials {
  access: string;
  refresh: string;
}
export type LoginInput =
  | {
      provider: 'credentials';
      email: string;
      password: string;
    }
  | {
      provider: 'google';
      code: string;
    };

const AuthContext = createContext<AuthContext | null>(null);

export function AuthProvider({ children }: { children: ReactNode }) {
  const [isLoading, setIsLoading] = useState(false);
  const [cookie] = useCookies([ACCESS_COOKIE, REFRESH_COOKIE]);

  const login = useCallback(async ({ provider, ...body }: LoginInput) => {
    if (provider === 'credentials') {
      await api<Credentials>('/api/v1/auth/login/', {
        method: 'POST',
        body,
        async onResponse({ response }) {
          if (response?.ok) {
            const { access, refresh } = response._data;
            setCredentials({ access, refresh });
          }
        },
      });
    }

    if (provider === 'google') {
      await api<{ access: string; refresh: string }>('/api/v1/auth/google/', {
        method: 'POST',
        body,
        onResponse({ response }) {
          if (response?.ok) {
            const { access, refresh } = response._data;
            setCredentials({ access, refresh });
          }
        },
      });
    }
  }, []);

  const logout = useCallback(async () => {
    await api<void>('/api/v1/auth/logout/', {
      method: 'POST',
      body: {
        refresh: cookie[REFRESH_COOKIE],
      },
      onResponse({ response }) {
        if (response?.ok) {
          clearCredentials();
        }
      },
    });
  }, [cookie[REFRESH_COOKIE]]);

  const value = useMemo(
    () => ({
      isAuthenticated: !!cookie[REFRESH_COOKIE],
      isLoading,
      setIsLoading,
      login,
      logout,
    }),
    [cookie[REFRESH_COOKIE], isLoading, login, logout],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

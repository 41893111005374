import { useAuth } from '@/auth';
import { Button } from '@/components/ui/button';
import { useDialogs } from '@/dialogs';
import { useNavigate } from '@tanstack/react-router';

export function Pricing() {
  const { isAuthenticated } = useAuth();
  const { showDialog } = useDialogs();
  const navigate = useNavigate();

  return (
    <section className="container py-20">
      <h2 className="text-center font-bold text-5xl">
        Simple pricing, no commitment
      </h2>

      <div className="mx-auto mt-20 flex max-w-sm flex-col gap-6 rounded-xl border bg-white p-4 md:flex-row md:justify-between md:p-8">
        <div className="flex">
          <p className="font-bold text-4xl text-foreground">$20</p>
          <div className="ms-3 text-muted-foreground text-sm">
            <p>USD</p>
            <p>Billed monthly</p>
          </div>
        </div>

        <div className="grid gap-4 md:gap-2">
          <Button
            type="button"
            onClick={() => {
              if (!isAuthenticated) {
                return navigate({ to: '/signin' });
              }
              showDialog('pricing');
            }}
          >
            Buy this plan
          </Button>

          <p className="text-center text-muted-foreground text-sm">
            Cancel anytime.
          </p>
        </div>
      </div>
    </section>
  );
}

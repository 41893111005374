import { DropdownProfile } from '@/components/dropdown-profile';
import { Navlinks } from '@/components/navlinks';
import { useDialogs } from '@/dialogs';
import { fetchProfileOptions } from '@/models/profile';
import { useQuery } from '@tanstack/react-query';
import {
  Link,
  Outlet,
  createFileRoute,
  redirect,
} from '@tanstack/react-router';
import { SparklesIcon } from 'lucide-react';

export const Route = createFileRoute('/_layout')({
  beforeLoad: ({ location, context }) => {
    if (!context.auth.isAuthenticated) {
      throw redirect({
        to: '/signin',
        search: {
          redirect: location.href,
        },
      });
    }
  },
  component: Layout,
});

function Layout() {
  const { showDialog } = useDialogs();
  const { data: profile, isError, isPending } = useQuery(fetchProfileOptions);

  if (isError) return null;
  if (isPending) return null;

  const fullName = `${profile?.first_name} ${profile?.last_name}`;
  const initials = `${profile?.first_name?.[0]}${profile?.last_name?.[0]}`;
  const email = profile?.email;
  const isPremium = profile?.subscribed;
  const remainingQueries = profile?.remaining_queries || 0;

  return (
    <div className="md:grid md:grid-cols-[auto_1fr]">
      <div className="flex-shrink-0 border-r bg-card">
        <aside className="hidden w-[260px] md:block">
          <nav className="flex h-dvh w-full flex-col px-3 py-3.5">
            <div className="flex-1 overflow-y-auto">
              <header className="sticky top-0">
                <Link className="flex items-center gap-x-2" to="/">
                  <img
                    className="aspect-square w-10 rounded-full"
                    src="/header-logo.webp"
                    alt="BytePath logo"
                  />
                  <span className="font-bold text-xl tracking-tight">
                    BytePath
                  </span>
                </Link>
              </header>

              <Navlinks />
            </div>

            <div className="flex flex-col pt-2 empty:hidden">
              {!profile?.subscribed ? (
                <button
                  className="flex w-full items-center gap-2 rounded-lg p-2 text-sm transition-all duration-300 hover:bg-background"
                  type="button"
                  onClick={() => showDialog('pricing')}
                >
                  <span className="flex h-10 w-10 items-center justify-center rounded-full border border-foreground p-1 text-foreground">
                    <SparklesIcon className="h-8 w-8" />
                  </span>
                  <div className="-top-px -space-y-px relative grow overflow-hidden text-ellipsis whitespace-nowrap text-left text-foreground">
                    <div className="font-semibold">Upgrade plan</div>
                  </div>
                </button>
              ) : null}

              <DropdownProfile
                fullName={fullName}
                initials={initials}
                email={email}
                isPremium={isPremium}
                remainingQueries={remainingQueries}
              />
            </div>
          </nav>
        </aside>
      </div>

      <Outlet />
    </div>
  );
}

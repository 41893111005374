import { api } from '@/utils/fetch';
import { queryOptions } from '@tanstack/react-query';

export interface Invoice {
  id: string;
  invoice_number: string;
  status: string;
  amount: number;
  pdf: string;
  created: string;
}

export async function createSubscription(options?: DefaultFetchOptions) {
  return api<{ url: string }>('/api/v1/stripe/subscribe/', {
    method: 'POST',
    signal: options?.signal,
  });
}

export async function fetchInvoices(options?: DefaultFetchOptions) {
  return api<Invoice[]>('/api/v1/stripe/invoices/', {
    method: 'GET',
    signal: options?.signal,
  });
}

export const fetchInvoicesOptions = queryOptions({
  queryKey: ['invoices'],
  queryFn: ({ signal }) => fetchInvoices({ signal }),
  staleTime: Number.POSITIVE_INFINITY,
});

export async function cancelSubscription(options?: DefaultFetchOptions) {
  return api<{ url: string }>('/api/v1/stripe/unsubscribe/', {
    method: 'POST',
    signal: options?.signal,
  });
}

import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/components/ui/collapsible';
import { navigationLinks } from '@/utils/constants';
import { Link, useMatches } from '@tanstack/react-router';
import { createElement, useState } from 'react';

export function Navlinks() {
  const matches = useMatches();
  const [isTemplates, setIsTemplates] = useState(
    matches.some((match) => match.routeId.includes('templates')),
  );

  return (
    <ul className="space-y-1.5 py-4 font-semibold text-foreground">
      {navigationLinks.map((link) =>
        'href' in link ? (
          <li key={link.href}>
            <Link
              className="group flex items-center gap-x-3 rounded-lg px-3 py-2 text-sm hover:bg-accent"
              activeProps={{
                className: 'bg-accent text-primary font-bold',
              }}
              to={link.href}
            >
              {createElement(link.icon, {
                className: 'group-data-[status=active]:text-primary',
              })}
              {link.name}
            </Link>
          </li>
        ) : (
          <Collapsible
            key={link.name}
            open={isTemplates}
            onOpenChange={setIsTemplates}
            asChild
          >
            <li>
              <CollapsibleTrigger className="group flex w-full items-center gap-x-3 rounded-lg px-3 py-2 text-sm hover:bg-accent">
                {createElement(link.icon, {
                  className: 'group-data-[status=active]:text-primary',
                })}
                {link.name}
              </CollapsibleTrigger>
              <CollapsibleContent>
                <ul>
                  {link.children.map((child) => (
                    <li key={child.href}>
                      <Link
                        className="group flex items-center gap-x-3 rounded-lg py-2 pr-3 pl-12 text-sm hover:bg-accent"
                        activeProps={{
                          className: 'bg-accent text-primary font-bold',
                        }}
                        to={child.href}
                      >
                        {child.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </CollapsibleContent>
            </li>
          </Collapsible>
        ),
      )}
    </ul>
  );
}

import { useAuth } from '@/auth';
import { Navlinks } from '@/components/navlinks';
import { Button, buttonVariants } from '@/components/ui/button';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import { MenuIcon } from 'lucide-react';

export function MobileNavigation() {
  const { logout } = useAuth();

  return (
    <Sheet>
      <SheetTrigger
        className={buttonVariants({
          className: 'float-end ml-auto md:hidden',
          variant: 'outline',
          size: 'icon',
        })}
      >
        <MenuIcon />
      </SheetTrigger>
      <SheetContent className="grid grid-rows-[1fr_auto] items-start">
        <Navlinks />

        <Button
          variant="ghost"
          type="button"
          onClick={async () => {
            await logout();
            window.location.href = '/';
          }}
        >
          Sign Out
        </Button>
      </SheetContent>
    </Sheet>
  );
}

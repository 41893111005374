import { createFileRoute } from '@tanstack/react-router';
import TagManager from 'react-gtm-module';

export const Route = createFileRoute('/_layout/thank-you')({
  loader: ({ route }) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'purchaseComplete',
        info: {
          oldPricingPlan: 'free',
          newPricingPlan: 'premium',
          currency: 'USD',
          value: 20,
        },
      },
    });
    route.router?.invalidate();
  },
});

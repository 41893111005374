import { jwtDecode } from 'jwt-decode';
import { ofetch } from 'ofetch';
import { Cookies } from 'react-cookie';
import { ACCESS_COOKIE, REFRESH_COOKIE } from './constants';

interface TokenResponse {
  access: string;
  refresh: string;
}

const cookies = new Cookies();

export const api = ofetch.create({
  baseURL: import.meta.env.VITE_API_URL,
  retry: 1,
  retryStatusCodes: [401],
  onRequest({ options }) {
    const token = cookies.get<string>(ACCESS_COOKIE);
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }
  },
  async onResponse({ response }) {
    if (response.status === 401) {
      try {
        const refresh = cookies.get<string>(REFRESH_COOKIE);

        if (!refresh) {
          throw new Error('No refresh token');
        }

        const response = await ofetch<TokenResponse>(
          '/api/v1/auth/token/refresh/',
          {
            baseURL: import.meta.env.VITE_API_URL,
            method: 'POST',
            body: { refresh },
          },
        );

        if (response.access && response.refresh) {
          setCredentials({
            access: response.access,
            refresh: response.refresh,
          });
        } else {
          throw new Error('Invalid response');
        }
      } catch (error) {
        clearCredentials();
      }
    }
  },
});

export function setCredentials({
  access,
  refresh,
}: {
  access: string;
  refresh: string;
}) {
  const decodedAccess = jwtDecode(access);
  const decodedRefresh = jwtDecode(refresh);
  if (decodedAccess.exp && decodedRefresh.exp) {
    cookies.set(ACCESS_COOKIE, access, {
      expires: new Date(decodedAccess.exp * 1000),
      path: '/',
      sameSite: 'lax',
      secure: import.meta.env.PROD,
    });
    cookies.set(REFRESH_COOKIE, refresh, {
      expires: new Date(decodedRefresh.exp * 1000),
      path: '/',
      sameSite: 'lax',
      secure: import.meta.env.PROD,
    });
  }
}

export function clearCredentials() {
  cookies.remove(ACCESS_COOKIE, { path: '/' });
  cookies.remove(REFRESH_COOKIE, { path: '/' });
}

import { Button } from '@/components/ui/button';
import { DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { createSubscription } from '@/models/payment';
import { useState } from 'react';

export function DialogPricing() {
  const [isDisabled, setIsDisabled] = useState(false);

  return (
    <>
      <DialogHeader>
        <DialogTitle>Upgrade your plan</DialogTitle>
      </DialogHeader>

      <div className="mt-5">
        <span className="font-bold text-6xl text-foreground">$20</span>
        <span className="font-bold text-foreground text-lg">.00</span>
        <span className="ms-3 text-muted-foreground">USD / monthly</span>
      </div>

      <div className="mt-5 grid grid-cols-2 gap-x-4 py-4 first:pt-0 last:pb-0">
        <div>
          <p className="text-muted-foreground text-sm">Cancel anytime.</p>
        </div>

        <div className="flex justify-end">
          <Button
            type="button"
            disabled={isDisabled}
            onClick={async () => {
              setIsDisabled(true);
              const { url } = await createSubscription();
              setIsDisabled(false);
              window.location.href = url;
            }}
          >
            Checkout
          </Button>
        </div>
      </div>
    </>
  );
}

import { ButtonGoogleSignIn } from '@/components/button-google-signin';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { fetchProfile } from '@/models/profile';
import { api } from '@/utils/fetch';
import { isValidationError } from '@/utils/guards';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  type HistoryState,
  Link,
  createFileRoute,
  useNavigate,
} from '@tanstack/react-router';
import { FetchError } from 'ofetch';
import TagManager from 'react-gtm-module';
import { useForm } from 'react-hook-form';
import { useHead } from 'unhead';
import { z } from 'zod';

export const Route = createFileRoute('/_auth/signup')({
  component: Login,
});

const schema = z
  .object({
    first_name: z.string(),
    last_name: z.string(),
    email: z.string().email({ message: 'Invalid email address' }),
    password1: z
      .string()
      .min(8, { message: 'Password must contain at least 8 characters' }),
    password2: z.string(),
  })
  .superRefine(({ password1, password2 }, { addIssue }) => {
    if (password1 !== password2) {
      addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Password mismatch',
        path: ['password2'],
      });
    }
  });

function Login() {
  const navigate = useNavigate();
  useHead({ title: 'Sign Up' });

  const form = useForm<z.infer<typeof schema>>({
    mode: 'onBlur',
    resolver: zodResolver(schema),
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      password1: '',
      password2: '',
    },
  });

  const onSubmit = form.handleSubmit(async (body) => {
    try {
      const credentials = await api<{ access: string; refresh: string }>(
        '/api/v1/auth/register/',
        {
          method: 'POST',
          body,
        },
      );
      const profile = await fetchProfile();

      if (profile) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'registrationComplete',
            info: { pricingPlan: 'free', user_email: profile.email },
          },
        });
      }

      return navigate({
        to: '/register-verify',
        state: { credentials } as HistoryState,
      });
    } catch (error) {
      if (error instanceof FetchError && isValidationError(error?.data)) {
        for (const err of error.data.errors) {
          if (err.attr === 'non_field_errors') {
            form.setError('root', { message: err.detail });
          } else {
            form.setError(err.attr as keyof z.infer<typeof schema>, {
              message: err.detail,
            });
          }
        }
      }
    }
  });

  return (
    <div className="w-full max-w-md rounded-xl border border-gray-200 bg-white shadow-sm">
      <div className="p-4 sm:p-7">
        <img
          className="mx-auto aspect-square w-32 rounded-full"
          src="/header-logo.webp"
          alt="Bytepath"
        />
        <div className="mt-4 text-center">
          <h1 className="block font-bold text-2xl text-foreground">Sign up</h1>
          <p className="mt-2 text-gray-600 text-sm">
            You already have an account?{' '}
            <Link
              className="font-medium text-accent-foreground decoration-2 hover:underline"
              to="/signin"
            >
              Sign in here
            </Link>
          </p>
        </div>

        <div className="mt-5">
          <ButtonGoogleSignIn />

          <div className="flex items-center py-3 text-gray-600 text-xs uppercase after:ms-6 before:me-6 after:flex-[1_1_0%] before:flex-[1_1_0%] after:border-gray-200 before:border-gray-200 after:border-t before:border-t">
            Or
          </div>

          <Form {...form}>
            <form onSubmit={onSubmit}>
              <div className="grid gap-y-4">
                <FormField
                  control={form.control}
                  name="first_name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>First name</FormLabel>
                      <FormControl>
                        <Input autoComplete="given-name" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="last_name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Last name</FormLabel>
                      <FormControl>
                        <Input autoComplete="family-name" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Email</FormLabel>
                      <FormControl>
                        <Input
                          type="email"
                          inputMode="email"
                          autoComplete="email"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="password1"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="flex items-center justify-between">
                        Password
                      </FormLabel>
                      <FormControl>
                        <Input
                          type="password"
                          autoComplete="new-password"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="password2"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="flex items-center justify-between">
                        Confirm password
                      </FormLabel>
                      <FormControl>
                        <Input
                          type="password"
                          autoComplete="new-password"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                {form.formState.errors.root ? (
                  <p
                    className="font-semibold text-red-700 text-xs"
                    role="alert"
                  >
                    {form.formState.errors.root.message}
                  </p>
                ) : null}

                <Button disabled={form.formState.isSubmitting}>Sign Up</Button>
              </div>
            </form>
          </Form>
        </div>
      </div>
    </div>
  );
}

import { api } from '@/utils/fetch';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/auth/email-verify/$token')({
  beforeLoad: async ({ params, navigate }) => {
    api(`/api/auth/verify/${params.token}`, {
      onResponse({ response }) {
        if (response.ok) {
          navigate({ to: '/' });
        }
      },
    });
  },
});

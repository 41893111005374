import { Button } from '@/components/ui/button';
import {
  DialogClose,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { useDialogs } from '@/dialogs';
import { cancelSubscription } from '@/models/payment';
import { useState } from 'react';

export function DialogUnsubscribe() {
  const [isDisabled, setIsDisabled] = useState(false);
  const { setOpen } = useDialogs();

  return (
    <>
      <DialogHeader>
        <DialogTitle>Are you sure you want to unsubscribe?</DialogTitle>
      </DialogHeader>

      <DialogFooter>
        <DialogClose>Close</DialogClose>
        <Button
          variant="destructive"
          type="button"
          disabled={isDisabled}
          onClick={async () => {
            setIsDisabled(true);
            await cancelSubscription();
            setIsDisabled(false);
            setOpen(false);
          }}
        >
          Unsubscribe
        </Button>
      </DialogFooter>
    </>
  );
}

import { useAuth } from '@/auth';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { cn } from '@/lib/utils';
import { useNavigate } from '@tanstack/react-router';

interface DropdownProfileProps {
  fullName: string;
  initials: string;
  email: string;
  isPremium: boolean;
  remainingQueries: number;
}

export function DropdownProfile({
  fullName,
  initials,
  email,
  isPremium,
  remainingQueries,
}: DropdownProfileProps) {
  const navigate = useNavigate();
  const { logout } = useAuth();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="flex w-full items-center gap-2 rounded-lg p-2 text-sm transition-all duration-300 hover:bg-background">
        <Avatar>
          <AvatarFallback className="uppercase">
            {initials || email[0]}
          </AvatarFallback>
        </Avatar>

        <div className="-top-px -space-y-px relative grow overflow-hidden text-ellipsis whitespace-nowrap text-left text-foreground">
          <div className="font-semibold">{fullName || email}</div>
          <small
            className={cn('text-muted-foreground', {
              'text-emerald-500': isPremium,
            })}
          >
            {isPremium ? 'Premium' : `Remaining queries: ${remainingQueries}`}
          </small>
        </div>
      </DropdownMenuTrigger>

      <DropdownMenuContent className="[width:var(--radix-popper-anchor-width)]">
        <DropdownMenuLabel>My Account</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem onSelect={() => navigate({ to: '/profile' })}>
          Profile
        </DropdownMenuItem>
        <DropdownMenuItem
          onSelect={async () => {
            await logout();
            navigate({ to: '/' });
          }}
        >
          Sign Out
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

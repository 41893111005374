import { Footer } from '@/components/footer';
import { Header } from '@/components/header';
import { HomeFeatures } from '@/components/home/home-features';
import { HomeHero } from '@/components/home/home-hero';
import { Pricing } from '@/components/home/home-pricing';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/')({
  component: Index,
});

function Index() {
  return (
    <>
      <Header />
      <HomeHero />
      <HomeFeatures />
      <Pricing />
      <Footer />
    </>
  );
}
